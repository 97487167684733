@use 'styles/base/mixins';
@use 'styles/base/variables';

.tagSection {
  margin-top: 2.5rem;

  h4 {
    margin-bottom: 0.5rem;
  }
}

.tagWrapper {
  display: flex;
  flex-wrap: wrap;
  row-gap: 0.25rem;

  @include mixins.screen-md {
    row-gap: 0rem;
  }

  .tag {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0.625rem 0 0;
    box-sizing: border-box;
    height: 2.5rem;
    border: 1px solid transparent;
    outline: 1px solid transparent;

    .tagBody {
      margin-top: 0;
      padding: 0 12px;
      height: 1.75rem;
      background-color: variables.$gb_grey_055;

      &:hover {
        cursor: pointer;
        background-color: variables.$gb_gdds_cl16;
      }

      &:active {
        background-color: variables.$gb_grey_800;
        color: #ffffff;
      }
    }

    span {
      font-size: 0.875rem;
      display: inline-block;
    }

    &.onFocus {
      border: 1px solid var(--gb-brand-primary-dark);
      outline: 1px solid var(--gb-brand-primary-dark);
    }
  }
}
