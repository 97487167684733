@use '../../checkout.module.scss';
@use 'styles/base/variables';

.addressBoxWrapper {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;

  > li {
    padding: 0;
    font-weight: variables.$font-weight-regular;
  }
}

.listEntryWithLabel {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
}

.listEntryWithLabel__label {
  font-size: 14px;
  color: gray;
  line-height: 1;
}

.bottomMargin {
  margin-bottom: variables.$spacing-xs;
}
