@use 'styles/base/mixins';

.headline {
  &#{&}#{&} {
    display: block;
    z-index: 1;
    text-transform: uppercase;

    b {
      display: block;
    }

    span {
        display: block;
    }

    &.h1,
    &.h1 span {
      @include mixins.typography-h-1();
    }

    &.h2,
    &.h2 span {
      @include mixins.typography-h-2();
    }
  }
}
